<template>
  <div v-if="section">
    <div class="mt-5 mb-2 px-3">
      <template v-if="section.egress_profile_matter != null">
        <h4>
          Información de la
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="egress_profile_matter && matter"
          :Matter="egress_profile_matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
      <template v-else-if="section.matter != null">
        <h4>
          Información de la
          {{ $getVisibleNames("teaching.matter", false, "Asignatura Base") }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
    </div>
    <div class="mt-2 mb-5">
      <h4>
        {{ $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones") }}
      </h4>
      <ManageEvaluationTable
        :key="key_new_generic_table"
        :pre_filter="pre_filter"
        :evaluation_scoped="selected_evaluations_scoped"
        :evaluation_scoped_object_id="section_id"
        :Sections="[section]"
        :Matters="[]"
        :EgressProfileMatters="[]"
        :redirect_to_evaluatee_view="true"
        :allows_crud="allows_crud"
      ></ManageEvaluationTable>
    </div>
    <b-modal
      :title="
        'Crear ' +
        $getVisibleNames('evaluations2.evaluation', false, 'Evaluación')
      "
      hide-footer
      size="xl"
      id="modal-created-evaluation"
    >
      <NewEvaluationForm
        :evaluation_scoped_id="selected_evaluations_scoped"
        :evaluation_scoped_object_id="section_id"
        :Sections="[section]"
        :Matters="[]"
        :EgressProfileMatters="[]"
        :show_step="selected_evaluations_scoped == 1"
        @closeModal="closeModal()"
      ></NewEvaluationForm>
    </b-modal>
    <NewDashboardCustomFab
      v-if="section_id"
      :section_id="section_id"
      :show_evaluatee_evaluations="false"
      :show_student_views="true"
    ></NewDashboardCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EvaluateeEvaluationsListView",
  components: {
    ManageEvaluationTable: () =>
      import(
        "@/components/new-evaluations/ManageEvaluations/ManageEvaluationTable"
      ),
    NewDashboardCustomFab: () =>
      import("@/components/dashboard/NewDashboardCustomFab"),
    NewMatterPresentation: () =>
      import(
        "@/components/teaching/MatterProgram/Components/NewMatterPresentation.vue"
      ),
    NewEvaluationForm: () =>
      import(
        "@/components/new-evaluations/ManageEvaluations/NewEvaluationForm"
      ),
  },
  props: {},
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      selected_evaluations_scoped: 1,
      allows_crud: false,
      key_new_generic_table: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    egress_profile_matter() {
      if (this.section && this.section.egress_profile_matter != null)
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      else return null;
    },
    matter() {
      if (this.section && this.section.matter != null)
        return this.matters.find((x) => x.id == this.section.matter);
      else if (
        this.egress_profile_matter &&
        this.egress_profile_matter.matter != null
      )
        return this.matters.find(
          (x) => x.id == this.egress_profile_matter.matter
        );
      else return null;
    },
    pre_filter() {
      let partial_url =
        "evaluation_scope_default__scope=" + this.selected_evaluations_scoped;
      if (this.section_id != null)
        partial_url =
          partial_url +
          "&evaluation_scope_default__scope_id=" +
          this.section_id;
      // filtro especial para ver si está como creador, o dentro del tests__test_users_default__evaluators__id
      if (this.user && this.user.groups.includes(6))
        partial_url = partial_url + "&evaluator=" + this.user.id;
      // if (this.user)
      //   partial_url =
      //     partial_url +
      //     "&tests__test_users_default__evaluators__id=" +
      //     this.user.id;
      return partial_url;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-created-evaluation");
      this.key_new_generic_table++;
    },
  },
  mounted() {},
  created() {
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_TYPES);
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        if (section.egress_profile_matter != null) {
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE_MATTER,
              section.egress_profile_matter
            )
            .then((egress_profile_matter) => {
              this.$store.dispatch(
                names.FETCH_MATTER,
                egress_profile_matter.matter
              );
            });
        } else if (section.matter != null) {
          this.$store.dispatch(names.FETCH_MATTER, section.matter);
        }
      });
  },
};
</script>

<style scoped>
</style>

